/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Wally from "@components/sharedComponents/customersLogos/logos/Wally.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Savar from "@components/sharedComponents/customersLogos/logos/Savar.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/chatbots-para-whatsapp.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/automatizacion-whatsapp.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/confirmacion-asistencia-por-whatsapp.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/whatsapp-vs-email.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/whatsapp-sms.jpg"

// logos testimonios
import AvivaTes from "@components/sharedComponents/testimonies/images/logos/aviva.svg"
import SavarTes from "@components/sharedComponents/testimonies/images/logos/savar.svg"
import CepebanTes from "@components/sharedComponents/testimonies/images/logos/cepeban.svg"
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"

//foto testimonio desktop
import avivaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/aviva.png"
import savarDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/savar.png"
import cepebanDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cepeban.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"

//foto testimonio desktop
import avivaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/aviva.png"
import savarMobile from "@components/sharedComponents/testimonies/images/fotosMobile/savar.png"
import cepebanMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cepeban.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageFunctionalities/pageChatbotsWhatsapp/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageFunctionalities/pageChatbotsWhatsapp/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageFunctionalities/pageChatbotsWhatsapp/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageFunctionalities/pageChatbotsWhatsapp/images/accordionLeft/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/Video82.webp"

import { TitleUnderlineChatbotsWhatsappOne } from "@components/pageFunctionalities/components/intro/titleUnderlineChatbotsWhatsapp"
import imageStartChatbotsWhatsapp from "@components/pageFunctionalities/components/intro/images/imageStartChatbotsWhatsapp.webp"

import ImageVerticalOne from "@components/pageFunctionalities/pageChatbotsWhatsapp/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalTwo from "@components/pageFunctionalities/pageChatbotsWhatsapp/images/imagesNavbarVertical/imageTwo.webp"
import ImageVerticalThree from "@components/pageFunctionalities/pageChatbotsWhatsapp/images/imagesNavbarVertical/imageThree.webp"
import ImageVerticalFour from "@components/pageFunctionalities/pageChatbotsWhatsapp/images/imagesNavbarVertical/imageFour.webp"

//image indrustias
import banca from "@components/pageFunctionalities/pageChatbotsWhatsapp/industries/images/banca.png"
import salud from "@components/pageFunctionalities/pageChatbotsWhatsapp/industries/images/salud.png"
import ecommerce from "@components/pageFunctionalities/pageChatbotsWhatsapp/industries/images/ecommerce.png"
import educacion from "@components/pageFunctionalities/pageChatbotsWhatsapp/industries/images/educacion.png"
import concesionaria from "@components/pageFunctionalities/pageChatbotsWhatsapp/industries/images/concesionaria.png"
import inmobiliaria from "@components/pageFunctionalities/pageChatbotsWhatsapp/industries/images/inmobiliaria.png"

const titleStart = (
  <p>
    Automatiza tu atención al cliente por WhatsApp con <TitleUnderlineChatbotsWhatsappOne underline="Chatbots con IA" />
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Implementa bots inteligentes dentro de WhatsApp para automatizar las respuestas y gestionar las consultas de clientes 24/7, mejorando la experiencia del usuario y liberando a tu equipo para que se concentre en tareas de mayor valor.
        <br /><br />
        Clasifica las consultas de los bots para crear enrutamientos inteligentes a los agentes adecuados. Envía mensajes interactivos, encuestas de satisfacción y mucho más.
      </p>
    ),
    image: imageStartChatbotsWhatsapp,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Unifef,
    Auna,
    Astara,
    Wally,
    Proempresa,
    Civa,
    G4S,
    Savar,
    Escampa,
    Royal
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Unifef, Auna, Astara, Wally],
    arrayTwo: [Proempresa, Civa, G4S, Savar, Escampa, Royal],
  },
  articles: [
    {
      image: article1,
      title: "5 razones para implementar chatbots para WhatsApp en tu negocio",
      textbody:
        "Conoce qué son los chatbots para WhatsApp y empieza a optimizar la atención al cliente digital y las ventas de tu negocio.",
      link: "https://beexcc.com/blog/chatbots-para-whatsapp/",
      card: true,
    },
    {
      image: article2,
      title: "¿Cómo implementar la automatización de WhatsApp en simples pasos?",
      textbody:
        "Descubre qué es la automatización de WhatsApp y cómo implementarlo con estos pasos y consejos. ¡ENTRA AQUÍ y eleva la productividad de tus equipos!",
      link: "https://beexcc.com/blog/automatizacion-whatsapp/",
      card: true,
    },
    {
      image: article3,
      title: "Confirmación de asistencia por WhatsApp: organiza tus eventos con estos pasos y consejos",
      textbody:
        "Descubre qué es la confirmación de asistencia por WhatsApp. ¡ENTRA AQUÍ y prepara con éxito tus eventos, desde citas médicas hasta capacitaciones!",
      link: "https://beexcc.com/blog/confirmacion-asistencia-por-whatsapp/",
    },
    {
      image: article4,
      title: "WhatsApp vs Email marketing: ¿Cuál tiene un mayor impacto?",
      textbody:
        "Creamos un WhatsApp vs Email para conocer sus fortalezas y debilidades de cada uno y así sepas cuál es el ideal para tu empresa.",
      link: "https://beexcc.com/blog/whatsapp-vs-email/",
    },
    {
      image: article5,
      title: "WhatsApp vs SMS: ¿Cuál es más efectivo para tu empresa?",
      textbody:
        "Creamos un WhatsApp vs SMS para conocer sus fortalezas y debilidades de cada uno y así sepas cuál es el ideal para tu empresa.",
      link: "https://beexcc.com/blog/whatsapp-sms/",
    },
  ],
  contacts: {
    title: "Empieza a automatizar tu canal de WhatsApp con bots con IA",
    textbody: "Déjanos contarte como puedes mejorar increíblemente la comunicación y fidelización de tu negocio o empresa con nuestra solución.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: AstaraTes,
      title:
        "Con Beex, hemos triplicado las ventas y reducido el esfuerzo de remarketing en 93%",
      textbody:
        "Astara, concesionaria de automóviles con presencia en Latam, ha automatizado sus procesos de marketing y ventas para canal orgánico y de paga con WhatsApp API.",
      name: "Jonny Castillo",
      post: "Jefe Comercial",
      link: "/casos-de-exito/astara/",
      imgDesktop: astaraDesktop,
      imgMobile: astaraMobile,
      metrics: [
        {
          metric: "x3",
          descripcion: "aumentó sus ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "93%",
          descripcion: "redujo su esfuerzo para remarketing",
          color: "#5831D8",
        },
        {
          metric: "80%",
          descripcion: "mejoró contactabilidad con leads",
          color: "#00CA56",
        },
      ],
    },
    {
      image: SavarTes,
      title:
        "Con Beex, hemos mejorado +20% la satisfacción de nuestros clientes en sus entregas de pedidos",
      textbody:
        "Savar, empresa líder en logística, ha mejorado sus métricas más importantes de satisfacción notificando sobre el estado de sus envíos por WhatsApp.",
      name: "Dinkar Rios Macedo",
      post: "Jefe de Sistemas",
      link: "/casos-de-exito/savar/",
      imgDesktop: savarDesktop,
      imgMobile: savarMobile,
      metrics: [
        {
          metric: "+20%",
          descripcion: "aumentó la satisfacción de sus clientes",
          color: "#FFB800",
        },
        {
          metric: "+30%",
          descripcion: "se redujeron las consultas repetitivas",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#00CA56",
        },
      ],
    },
    {
      image: AvivaTes,
      title:
        "Mejoramos el tiempo para agendar citas en +30%, automatizando procesos con chatbots por WhatsApp",
      textbody:
        "Aviva, parte del grupo Intercorp, clínica multiespecialidad con diferentes servicios en salud, ha logrado automatizar su proceso de citas médicas con chatbots en WhatsApp.",
      name: "Luis Ordinola",
      post: "Coordinador de mejora de procesos",
      link: "/casos-de-exito/clinica-aviva/",
      imgDesktop: avivaDesktop,
      imgMobile: avivaMobile,
      metrics: [
        {
          metric: "+30%",
          descripcion: "mejoró el tiempo de agenda de citas",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "ahora representa WhatsApp como canal para agendar citas",
          color: "#5831D8",
        },
      ],
    },
    {
      image: CepebanTes,
      title: "Con los chatbots de prospección, hemos mejorado la conversión de leads en +27%",
      textbody:
        "CEPEBAN, instituto especializado en banca y finanzas, ha aumentado a +1.5k la cantidad de leads mejor prospectados con chatbots por WhatsApp Business API.",
      name: "Alan De La Cruz",
      post: "Jefe de Sistemas",
      link: "/casos-de-exito/cepeban/",
      imgDesktop: cepebanDesktop,
      imgMobile: cepebanMobile,
      metrics: [
        {
          metric: "+27%",
          descripcion: "mejoró su tasa de conversión con sus leads",
          color: "#FFB800",
        },
        {
          metric: "+1.5k",
          descripcion: "leads prospectados al mes por chatbots",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#00CA56",
        },
      ],
    },
    {
      image: CasaRosselloTes,
      title:
        "Ahora ayudamos más rápido a nuestros clientes con nuestro bot, por el canal que prefieran",
      textbody:
        "Casa Rosselló, compañía con más de 150 años de historia en el sector acabados y construcción, prospecta de forma más óptima con WhatsApp API y livechat.",
      name: "Marlen Vega",
      post: "Jefa de Marketing",
      link: "/casos-de-exito/casa-rossello/",
      imgDesktop: casaRosselloDesktop,
      imgMobile: casaRosselloMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],
  accordionRight: [
    {
      title: "Bots con IA",
      description: "<p>Utilizamos inteligencia artificial en nuestros chatbots para que aprendan de las interacciones con los usuarios, mejorando su capacidad para entender consultas complejas y proporcionar respuestas más precisas y personalizadas.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Crea Workflows",
      description: "<p>Automatiza y gestiona flujos conversación por WhatsApp para la interacción con el cliente, desde el primer contacto hasta el servicio postventa, asegurando una experiencia coherente y eficiente en cada paso.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Integración con Herramientas",
      description: "<p>Integra los bots con una variedad de herramientas empresariales, como sistemas CRM, plataformas de e-commerce y herramientas de gestión de proyectos, para una gestión de datos unificada y acciones automatizadas basadas en la interacción del cliente.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Notificaciones y Alertas Personalizadas",
      description: "<p>Envía mensajes personalizadas sobre pedidos, citas, eventos y más, manteniendo a los clientes informados y comprometidos con tu empresa, lo que ayuda a mejorar la reputación frente a tus clientes.</p>",
      image: ImageAccordionLeftFour
    },
  ],
  bannerVideo: {
    title: "5 casos de uso de chatbots en WhatsApp para el sector logística ",
    description: "<p>Explora cómo los chatbots en WhatsApp optimizan procesos en el sector logística. Aprende sobre su aplicación en seguimiento de envíos, gestión de inventario, atención al cliente, coordinación de rutas y automatización de pedidos. <br /><br /> Encuentra información sobre su implementación y beneficios en distintas operaciones. </p>",
    image: imageVideo
  },
  navbarVerticalRight: [
    {
      tab: "1",
      title: "Proceso de Citas y Reservas",
      text: (
        <p>
          Automatiza la programación de citas y reservas, permitiendo a los clientes elegir fechas y horarios a través de una interfaz conversacional fácil de usar, simplificando el proceso y mejorando la satisfacción.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Gestión de Pedidos",
      text: (
        <p>
          Permite a tus usuarios tomar y procesar pedidos directamente a través de WhatsApp, ofreciendo una experiencia única de autoservicio luego de una compra, reduciendo la carga operativa del equipo de ventas.
        </p>
      ),
      image: ImageVerticalTwo,
    },
  ],
  navbarVerticalLeft: [
    {
      tab: "3",
      title: "Mensajes Interactivos",
      text: (
        <p>
          Implementa mensajes interactivos para enriquecer la experiencia del usuario, permitiendo a los clientes tomar acciones directas dentro de la conversación, como seleccionar opciones en botones o listas de mensajes.
        </p>
      ),
      image: ImageVerticalThree,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "4",
      title: "Mensajes multimedia",
      text: (
        <p>
          Envía mensajes que incluyen imágenes, videos, documentos y audios para proporcionar una experiencia más única y atractiva, ideal para mostrar productos, instrucciones o información relevante.
        </p>
      ),
      image: ImageVerticalFour,
    },
  ],
  navbarVerticalSilder: [
    {
      tab: "1",
      title: "Proceso de Citas y Reservas",
      text: (
        <p>
          Automatiza la programación de citas y reservas, permitiendo a los clientes elegir fechas y horarios a través de una interfaz conversacional fácil de usar, simplificando el proceso y mejorando la satisfacción.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Gestión de Pedidos",
      text: (
        <p>
          Permite a tus usuarios tomar y procesar pedidos directamente a través de WhatsApp, ofreciendo una experiencia única de autoservicio luego de una compra, reduciendo la carga operativa del equipo de ventas.
        </p>
      ),
      image: ImageVerticalTwo,
    },
    {
      tab: "3",
      title: "Mensajes Interactivos",
      text: (
        <p>
          Implementa mensajes interactivos para enriquecer la experiencia del usuario, permitiendo a los clientes tomar acciones directas dentro de la conversación, como seleccionar opciones en botones o listas de mensajes.
        </p>
      ),
      image: ImageVerticalThree,
    },
    {
      tab: "4",
      title: "Mensajes multimedia",
      text: (
        <p>
          Envía mensajes que incluyen imágenes, videos, documentos y audios para proporcionar una experiencia más única y atractiva, ideal para mostrar productos, instrucciones o información relevante.
        </p>
      ),
      image: ImageVerticalFour,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
  ],
  industries: [
    {
      tab: "01. E-commerce",
      title: "Chatbots en WhatsApp para E-commerce y Retail",
      text: (
        <p>
          <br />
          Notifica a los clientes sobre ofertas especiales, nuevos lanzamientos de productos y actualizaciones de estado de pedidos. Personaliza estas notificaciones, haciendo recomendaciones de productos basadas en compras anteriores o intereses mostrados.
          <br /> <br /> <br />
        </p>
      ),
      image: ecommerce,
      active: "container-industries-channels-navbar-menu-button-active",
      link: "/hablemos/",
      textLink: "Conoce chatbots para E-commerce",
    },
    {
      tab: "02. Banca y finanzas",
      title: "Chatbots en WhatsApp para Banca, Seguros, Financieras y más",
      text: (
        <p>
          <br />
          Envía alertas de seguridad, confirmaciones de transacciones, y actualizaciones de productos financieros. Facilita los recordatorios de pago y ofrecer asistencia en tiempo real, mejorando la relación cliente-banco y aumentando la confianza en los servicios ofrecidos.
          <br /> <br /> <br />
        </p>
      ),
      image: banca,
      link: "/hablemos/",
      textLink: "Conoce chatbots para Banca y Finanzas",
    },
    {
      tab: "03. Educación",
      title: "Chatbots en WhatsApp para Universidades, Institutos y Colegios",
      text: (
        <p>
          <br />
          Envía recordatorios de fechas importantes, como inscripciones, fechas de exámenes o eventos escolares. Permite una comunicación directa con estudiantes y padres, ofreciendo actualizaciones curriculares, material de estudio adicional y encuestas de satisfacción.
          <br /> <br /><br />
        </p>
      ),
      image: educacion,
      link: "/hablemos/",
      textLink: "Conoce chatbots para Eduación",
    },
    {
      tab: "04. Inmobiliarias",
      title: "Chatbots en WhatsApp para Inmobiliarias, Bienes y Raíces",
      text: (
        <p>
          <br />
          Mantén a los clientes potenciales informados sobre nuevas propiedades en el mercado, cambios de precios y días de puertas abiertas. Segmente tus mensajes y envía información relevante según las preferencias y necesidades de cada cliente, como ubicación deseada o rango de precios.
          <br /> <br /><br />
        </p>
      ),
      image: inmobiliaria,
      link: "/hablemos/",
      textLink: "Conoce chatbots para Inmobiliarias",
    },
    {
      tab: "05. Salud",
      title: "Chatbots en WhatsApp para Clínicas, Hospitales y Centros de Salud",
      text: (
        <p>
          <br />
          Facilita los recordatorios de citas, consejos de salud personalizados y alertas de vacunación. Además, puede ser una herramienta eficaz para campañas de concientización sobre temas de salud pública, contribuyendo al bienestar general.
          <br /> <br /><br />
        </p>
      ),
      image: salud,
      link: "/hablemos/",
      textLink: "Conoce chatbots para Salud",
    },
    {
      tab: "06. Concesionarias",
      title: "Chatbots en WhatsApp para  Concesionarias",
      text: (
        <p>
          <br />
          Envía recordatorios de mantenimiento, ofertas especiales en servicios o accesorios y lanzamientos de nuevos modelos. Personaliza estos mensajes basándose en el historial de servicios y preferencias del cliente puede incrementar las visitas al taller y fomentar la lealtad hacia la marca.
          <br /> <br /><br />
        </p>
      ),
      image: concesionaria,
      link: "/hablemos/",
      textLink: "Conoce chatbots para Concesionarias",
    },

  ],
}

export default data
